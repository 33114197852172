.body {
  width: 100%;
}
.body.placeholder {
  margin-bottom: 1.5em;
}
.body .body-line {
  width: 100%;
  height: 18px;
  margin-bottom: 0.2em;
  background-color: #252525;
}
.body .body-line:last-child {
  width: 60%;
}