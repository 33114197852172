@import '../../../../../../node_modules/slick-carousel/slick/slick.css';
@import '../../../../../../node_modules/slick-carousel/slick/slick-theme.css';
@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("/fonts/fontawesome/webfonts/fa-brands-400.eot");
  src: url("/fonts/fontawesome/webfonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("/fonts/fontawesome/webfonts/fa-brands-400.woff2") format("woff2"), url("/fonts/fontawesome/webfonts/fa-brands-400.woff") format("woff"), url("/fonts/fontawesome/webfonts/fa-brands-400.ttf") format("truetype"), url("/fonts/fontawesome/webfonts/fa-brands-400.svg#fontawesome") format("svg");
}
@font-face {
  font-family: "Font Awesome 5 Regular";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("/fonts/fontawesome/webfonts/fa-regular-400.eot");
  src: url("/fonts/fontawesome/webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("/fonts/fontawesome/webfonts/fa-regular-400.woff2") format("woff2"), url("/fonts/fontawesome/webfonts/fa-regular-400.woff") format("woff"), url("/fonts/fontawesome/webfonts/fa-regular-400.ttf") format("truetype"), url("/fonts/fontawesome/webfonts/fa-regular-400.svg#fontawesome") format("svg");
}
@font-face {
  font-family: "Font Awesome 5 Solid";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("/fonts/fontawesome/webfonts/fa-solid-900.eot");
  src: url("/fonts/fontawesome/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("/fonts/fontawesome/webfonts/fa-solid-900.woff2") format("woff2"), url("/fonts/fontawesome/webfonts/fa-solid-900.woff") format("woff"), url("/fonts/fontawesome/webfonts/fa-solid-900.ttf") format("truetype"), url("/fonts/fontawesome/webfonts/fa-solid-900.svg#fontawesome") format("svg");
}
.gallery .gallery-switcher {
  margin-bottom: 3rem;
}
.gallery .gallery-carousel {
  align-items: flex-end;
}
.gallery .arrow-prev,
.gallery .arrow-next {
  float: right;
  clear: both;
  font-size: 2.5rem;
  border: 3px solid #013e7d;
  height: 64px;
  width: 64px;
  border-radius: 64px;
  text-align: center;
  line-height: 1.65;
}
.gallery .arrow-prev {
  margin-top: 1rem;
  padding-right: 0.3rem;
}
.gallery .arrow-next {
  padding-left: 0.3rem;
  color: #41a8ce;
  border-color: #41a8ce;
}
@media (min-width: 768px) {
  .gallery .slick-list {
    max-width: 80vw;
    margin-right: 0;
    margin-left: auto;
  }
}
.gallery .slick-dots {
  text-align: right;
  padding: 0 10vw;
  font-family: "Font Awesome 5 Solid";
}
.gallery .slick-dots li {
  margin: 0;
}
.gallery .slick-dots li button:before {
  color: #003058;
  opacity: 1;
  content: "\f111";
  font-family: "Font Awesome 5 Regular";
  font-size: 12px;
}
.gallery .slick-dots li.slick-active {
  margin: 0;
}
.gallery .slick-dots li.slick-active button:before {
  font-family: "Font Awesome 5 Solid";
  color: #41a8ce;
}