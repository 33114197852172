.spinner {
  width: 100%;
  text-align: center;
}
.spinner img {
  height: 48px;
  width: 48px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}