.property-card {
  text-align: right;
}
.property-card .address div,
.property-card .property-description {
  color: #FFFFFF;
}
.property-card .address {
  font-size: 1.6rem;
  margin-bottom: 1.3rem;
}
.property-card .property-description {
  margin-bottom: 1.3rem;
  padding-left: 40%;
}
.property-card .image {
  margin-top: -10rem;
  padding: 0 3rem 0 2rem;
}
.property-card .property-detail-link {
  margin-top: 3rem;
}