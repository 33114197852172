.market-slider {
  position: relative;
  width: 90%;
  margin: auto;
}
.market-slider > .content {
  background-color: #003058;
}
.market-slider .slider {
  padding: 0;
}
.market-slider .arrows {
  width: max-content;
  margin: auto;
  margin-top: -50%;
  transform: translateY(50%);
  height: 100%;
}
.market-slider .arrows .arrow-prev,
.market-slider .arrows .arrow-next {
  font-size: 2.5rem;
  border: 3px solid #013e7d;
  height: 64px;
  width: 64px;
  border-radius: 64px;
  text-align: center;
  line-height: 1.65;
  color: #FFFFFF;
}
.market-slider .arrows .arrow-prev {
  margin-top: 1rem;
  padding-right: 0.3rem;
}
.market-slider .arrows .arrow-next {
  padding-left: 0.3rem;
  border-color: #41a8ce;
}