.property-grid-item {
  color: #FFFFFF;
  font-size: 1.6rem;
  margin-bottom: 2rem;
}
.property-grid-item a {
  color: #FFFFFF;
}
.property-grid-item.light a {
  color: #003058;
}