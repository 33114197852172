@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
.recent-articles-list .headline hr {
  display: none;
}
.recent-articles-list .headline.vertical hr {
  display: none;
}
@media (min-width: 992px) {
  .recent-articles-list .headline.vertical h2 {
    transform: translate(-50%, -100%) rotate(-90deg);
    transform-origin: bottom right;
    font-size: 3rem;
    width: max-content;
    background-color: #FFFFFF;
    z-index: 1;
    padding-left: 0.5rem;
  }
  .recent-articles-list .headline.vertical hr {
    display: block;
    transform: translateX(50%) rotate(-90deg);
    border-top: 2px solid #003058;
    width: 100%;
    position: absolute;
    transform-origin: left;
    bottom: 0;
    padding: 0;
    margin: 0;
    z-index: 0;
  }
}
.recent-articles-list .articles {
  border-top: 2px solid #003058;
  border-bottom: 2px solid #003058;
  padding-top: 2rem;
}
.recent-articles-list .more-link {
  text-align: right;
  margin-top: 1.5rem;
}
.recent-articles-list .more-link a {
  color: #003058;
  text-transform: uppercase;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  padding: 0.3rem 1rem;
  border-bottom: 2px solid #003058;
}