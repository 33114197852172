@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
.team-selector.align-center {
  text-align: center;
}
.team-selector .menu-branch {
  display: inline-block;
  padding: 0 1.5rem;
  font-size: 1.1rem;
  text-transform: uppercase;
  border-right: 1px solid #252525;
}
.team-selector .menu-branch a {
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
}
.team-selector .menu-branch:last-of-type {
  border-right: none;
}
.team-selector .menu-branch.active {
  color: #003058;
}
.team-selector .submenu {
  margin-top: 1rem;
}
.team-selector .submenu .menu-branch {
  font-size: 1rem;
}