.gallery-item {
  border-left: 1px solid #41a8ce;
  height: 300px;
}
@media (min-width: 768px) {
  .gallery-item {
    height: 700px;
  }
}
.gallery-item img {
  height: 100%;
  width: auto;
}