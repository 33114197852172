.search-result {
  margin-bottom: 3rem;
}
.search-result .icon {
  font-size: 2rem;
  color: #41a8ce;
  text-align: right;
}
.search-result .teaser {
  color: #252525;
  font-size: 1.1rem;
}