.market-slider-item {
  position: relative;
  border-left: 1px solid #41a8ce;
}
.market-slider-item .content {
  position: absolute;
  bottom: 0;
  background-color: transparent;
  padding: 4rem 1.5rem;
  z-index: 3;
}
.market-slider-item .active-indicator {
  background-color: #41a8ce;
  width: 55%;
  height: 8px;
  margin-bottom: 3rem;
}
.market-slider-item h3 {
  color: #FFFFFF;
  font-size: 3rem;
  margin-bottom: 0;
}
.market-slider-item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 48, 88, 0.75);
  height: 100%;
  width: 100%;
}

.slick-current .market-slider-item .overlay {
  display: none;
}