.text.paragraph img {
  max-width: 100%;
  width: auto;
}
.text.paragraph .button {
  margin-top: 1rem;
}
.text.paragraph blockquote {
  font-size: 2rem;
  margin: 0 5em;
}
.text.paragraph blockquote p {
  margin-top: 1.5em;
  line-height: 3;
  color: #41a8ce;
}
@media (max-width: 800px) {
  .text.paragraph blockquote {
    font-size: 1.1rem;
    margin: 0 3em;
  }
  .text.paragraph blockquote p {
    line-height: 2;
  }
}