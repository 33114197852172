.property-picker {
  background-color: #013e7d;
  padding: 2rem;
  margin-top: 0;
}
@media (min-width: 992px) {
  .property-picker {
    margin-top: 5rem;
    padding: 2rem 4rem 4rem;
  }
}