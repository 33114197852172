@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
.position-teaser {
  text-align: center;
  margin-bottom: 3rem;
}
.position-teaser .person-image {
  margin-bottom: 1rem;
}
.position-teaser .job-title {
  text-transform: uppercase;
  font-size: 0.9rem;
}
.position-teaser .person-name {
  font-size: 2rem;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
}