@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
.person-detail-page {
  position: relative;
  min-height: 900px;
}
.person-detail-page p {
  font-size: 1.1rem;
}
.person-detail-page div, .person-detail-page p {
  color: #252525;
}
.person-detail-page h1.title {
  font-size: 5.5rem;
  margin-bottom: 2rem;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  z-index: 2;
  position: relative;
}
@media (min-width: 768px) {
  .person-detail-page {
    padding-top: 4rem;
  }
  .person-detail-page .image {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    z-index: 1;
  }
  .person-detail-page .image img {
    z-index: 1;
  }
}
.person-detail-page .body-1,
.person-detail-page .body {
  padding-top: 2rem;
}
.person-detail-page .body-1 .page-section.padded,
.person-detail-page .body .page-section.padded {
  padding-bottom: 0;
}
.person-detail-page .body-2 {
  background-color: #003058;
  padding: 4rem 4rem 0;
}
.person-detail-page .body-2 div, .person-detail-page .body-2 span, .person-detail-page .body-2 h4, .person-detail-page .body-2 p {
  color: #FFFFFF;
}
@media (min-width: 768px) {
  .person-detail-page .body {
    margin-top: 3rem;
    column-count: 3;
    column-gap: 4rem;
    padding-top: 0;
  }
  .person-detail-page .body-1 .page-section.padded,
  .person-detail-page .body .page-section.padded {
    padding-bottom: 4rem;
  }
}
.person-detail-page .job-title {
  font-size: 2rem;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .person-detail-page.has-image .body-1 {
    width: 50%;
    padding-right: 12rem;
    padding-top: 0;
  }
  .person-detail-page.has-image .body-2 {
    padding: 4rem 12rem 0 4rem;
    width: 50%;
    transform: translateX(4rem);
  }
  .person-detail-page.has-image .job-title,
  .person-detail-page.has-image .title {
    width: 50%;
    padding-right: 3rem;
  }
}