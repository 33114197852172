@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
.page-footer h2 {
  color: #FFFFFF;
}
.page-footer .pre-footer {
  width: 100%;
  position: relative;
  z-index: 2;
  color: #ffffff;
}
.page-footer .pre-footer .flex-content {
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  text-align: center;
  padding: 2rem 0;
  width: 100%;
}
.page-footer .pre-footer .flex-content div {
  color: #41a8ce;
}
@media (min-width: 992px) {
  .page-footer .flex-container {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }
  .page-footer .flexible {
    display: flex;
    width: 50%;
  }
  .page-footer .limit-half-width {
    max-width: 700px;
  }
}
.page-footer .offset-up {
  margin-top: -60px;
}
.page-footer .offset-down {
  margin-top: 60px;
}
@media (min-width: 768px) {
  .page-footer .menu {
    display: flex;
    justify-content: center;
  }
}
.page-footer .menu a {
  color: #FFFFFF;
  padding: 0 1em;
  display: block;
  text-align: center;
}
@media (min-width: 768px) {
  .page-footer .menu a {
    border-left: 1px solid #FFFFFF;
  }
}
.page-footer .menu .menu-branch:first-child a {
  border-left: none;
}
@media (min-width: 768px) {
  .page-footer .menu .menu-branch:last-child a {
    padding-right: 0;
  }
}
.page-footer .footer-content {
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.page-footer .footer .col-12 {
  justify-content: center;
  margin-bottom: 1.5rem;
}
@media (min-width: 992px) {
  .page-footer .footer .col-12 {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .page-footer .footer-menu .menu {
    justify-content: flex-end;
  }
}
.page-footer .copyright {
  align-items: center;
}
@media (min-width: 992px) {
  .page-footer .copyright-menu .menu {
    justify-content: flex-start;
  }
}
.page-footer .icon-logo {
  align-items: center;
  text-align: center;
}
@media (min-width: 992px) {
  .page-footer .icon-logo {
    text-align: initial;
  }
}
.page-footer .icon-logo .logo-copyright {
  color: #FFFFFF;
}
.page-footer .icon-logo img {
  height: 48px;
  width: 48px;
  margin-right: 1em;
}
.page-footer .icon-logo .menu {
  margin-left: 1em;
}
.page-footer .icon-logo .menu .menu-branch:first-child a {
  border-left: 1px solid #FFFFFF;
}
.page-footer .fine-print {
  padding-bottom: 1rem;
  font-size: 0.7rem;
  font-style: italic;
}