@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
.virtual-tour {
  padding-top: 4rem;
}
.virtual-tour .content {
  height: 300px;
  padding: 0.75rem;
  background-position: center;
  background-size: cover;
}
@media (min-width: 768px) {
  .virtual-tour .content {
    padding: 2rem;
  }
}
.virtual-tour .content .headline {
  font-size: 3rem;
  color: #FFFFFF;
  text-transform: uppercase;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
}