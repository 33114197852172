.search-box {
  display: flex;
  align-items: flex-end;
}
.search-box form {
  display: flex;
}
.search-box form input {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #003058;
  font-size: 0.9rem;
}
.search-box form input.form-control {
  padding: 0.1rem;
  height: auto;
  background-color: transparent;
}
.search-box form input::placeholder {
  font-style: italic;
}
.search-box form .btn-primary {
  background-color: transparent;
  border: none;
  color: #003058;
  padding: 0;
  line-height: auto;
  font-size: 1.25rem;
}
.search-box .menu {
  margin-right: 1em;
}

.has-hero .search-box form input,
.search-box.theme-light form input {
  border-bottom: 2px solid #FFFFFF;
}
.has-hero .search-box form input.form-control,
.search-box.theme-light form input.form-control {
  color: #FFFFFF;
}
.has-hero .search-box form input::placeholder,
.search-box.theme-light form input::placeholder {
  color: #FFFFFF;
}
.has-hero .search-box form .btn-primary,
.search-box.theme-light form .btn-primary {
  color: #FFFFFF;
}