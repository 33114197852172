.market-card {
  padding: 3rem 3rem 0;
  border: none;
  text-align: center;
}
.market-card[role=button]:hover {
  cursor: pointer;
  background-color: #f8f8f8;
}
@media (min-width: 768px) {
  .market-card {
    padding-bottom: 3rem;
  }
}
.market-card .content {
  display: inline-block;
  text-align: left;
}
.market-card h3 {
  font-size: 2.5rem;
  padding-top: 1rem;
  text-align: center;
}
@media (min-width: 768px) {
  .market-card h3 {
    text-align: left;
  }
}
.market-card img {
  width: auto;
  height: auto;
  max-width: 200px;
  margin: auto;
  display: block;
}
@media (min-width: 768px) {
  .market-card img {
    max-width: 100%;
  }
}
.market-card .active-indicator {
  display: none;
  background-color: #41a8ce;
  width: 35%;
  height: 8px;
}
.market-card.active img {
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.3);
}
.market-card.active .active-indicator {
  display: block;
}