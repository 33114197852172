@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
.position-card {
  text-align: center;
}
.position-card.featured {
  text-align: left;
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .position-card.featured {
    text-align: right;
  }
}
.position-card img {
  width: auto;
  height: auto;
  margin-bottom: 1rem;
}
.position-card .job-title {
  text-transform: uppercase;
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}
.position-card .person-name {
  font-size: 1.2rem;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
}