@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
.button {
  text-transform: uppercase;
  padding: 0.6rem 2rem;
  border: 3px solid #003058;
  color: #FFFFFF;
  border-radius: 1.5rem;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  white-space: nowrap;
  display: inline-block;
}
.button.light {
  border: 3px solid #41a8ce;
  background-color: transparent;
}
.button.light.highlighted {
  border: none;
  background-color: #bfcfe0;
  color: #003058;
}
.button.dark {
  color: #013e7d;
}
.button.dark.ghost {
  border: 3px solid #FFFFFF;
  background-color: rgba(1, 62, 125, 0.7);
  color: #FFFFFF;
  opacity: 1;
  text-shadow: none;
}
.button.ghost {
  border: none;
  opacity: 0.7;
  background-color: #FFFFFF;
  color: #003058;
}