@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
@media (min-width: 992px) {
  .building-switcher {
    text-align: right;
  }
}
.building-switcher .building-address {
  padding: 0 0.7rem;
  font-size: 1.2rem;
}
.building-switcher .current-building,
.building-switcher .building-selector-wrapper {
  position: relative;
}
.building-switcher .building-status {
  color: #41a8ce;
  text-transform: uppercase;
  font-size: 0.8rem;
  margin-top: 1rem;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
}
.building-switcher .building-count {
  color: #41a8ce;
  padding: 0 0.7rem;
  display: inline-block;
}
.building-switcher .building-count:hover {
  cursor: pointer;
}
.building-switcher .item-list {
  text-align: left;
}