@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
header.page-header {
  padding: 1.5rem 0;
  margin-bottom: 4rem;
}
header.page-header.has-hero {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  color: #FFFFFF;
  margin-bottom: 0;
}
header.page-header.has-hero .menu a {
  color: #FFFFFF;
}
header.page-header .branding {
  display: flex;
  align-items: flex-end;
}
header.page-header .branding img {
  height: 48px;
  margin-right: 3rem;
}
header.page-header .menu a {
  color: #003058;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
}
header.page-header .main-menu .menu {
  display: flex;
}
header.page-header .main-menu .menu a {
  font-size: 1.2rem;
  padding: 0 0.8em;
}
header.page-header .search-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
header.page-header .page-header-content {
  align-items: flex-end;
}