@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
.building-mini {
  margin-bottom: 1rem;
}
.building-mini img {
  width: 100%;
}
.building-mini .title {
  font-size: 1.2em;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  color: #41a8ce;
  white-space: nowrap;
}