@import url(https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css);
@import url(https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css);
@import url(https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css);
@import url(https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css);
@import url(https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css);
@import url(https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css);
@import url(https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css);
@import url(https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css);
@import url(https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css);
@import url(https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css);
@import url(https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css);
@import url(https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css);
@import url(https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css);
@import url(https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css);
@import url(https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css);
@import url(https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css);
@import url(https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css);
@import url(https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css);
@import url(https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css);
@import url(https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css);
@import url(https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css);
@import url(https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css);
@import url(https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css);
/*!
 * Bootstrap Grid v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1400px;
  }
}
.editor-body h1.body-large-headline {
  font-size: 4rem;
}
.editor-body h4.body-subsection-title {
  font-size: 1rem;
  text-transform: uppercase;
  font-family: "Avenir LT W01_45 Book", arial, sans-serif;
}
.editor-body h4.body-subsection-title.secondary-color {
  color: #41a8ce;
}
.editor-body .body-large-bold {
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  font-size: 1.4rem;
}
.editor-body .body-large-bold.secondary-color {
  color: #41a8ce;
}
.editor-body .body-medium {
  font-size: 1.2rem;
}
.editor-body .text-align-center {
  text-align: center;
}
.editor-body .media[data-align=center] .field, .editor-body .media.align-center .field {
  text-align: center;
}
.editor-body .media[data-align=right] .field, .editor-body .media.align-right .field {
  text-align: right;
}
.editor-body .media .field {
  width: 100%;
}

p, input, label, select, span, div, html, body {
  color: #003058;
  font-family: "Avenir LT W01_45 Book", arial, sans-serif;
}
p.light, input.light, label.light, select.light, span.light, div.light, html.light, body.light {
  color: #FFFFFF;
}

.page-top {
  background-color: #FFFFFF;
}

body {
  background-color: #252525;
}

h1, h2, h3, h4, h5, h6 {
  color: #003058;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
}

a {
  color: #41a8ce;
}
a.light {
  color: #FFFFFF;
}
a:hover {
  text-decoration: none;
  color: #3ad8ff;
}

p {
  margin-bottom: 1.5em;
}

.content-wrapper {
  padding-bottom: 8rem;
}
.content-wrapper img {
  width: 100%;
  height: auto;
}

.page-section.padded {
  padding-bottom: 4rem;
}

.page-content {
  z-index: 3;
}

.btn {
  background-color: #FFFFFF;
  border: 1px solid #003058;
  border-radius: 21px;
  text-transform: uppercase;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  color: #003058;
  padding: 0.5em 2em;
}
.btn:active, .btn:focus, .btn:hover {
  color: #41a8ce;
  border-color: #41a8ce;
  background-color: #FFFFFF;
}

.background-prime {
  background-color: #013e7d;
}

.background-prime-dark {
  background-color: #003058;
}

.align-left {
  margin: 0 auto 0 0;
}

.align-right {
  margin: 0 0 0 auto;
}

@keyframes bounce-animation {
  0% {
    transform: translateY(0);
  }
  4% {
    transform: translateY(3px);
  }
  8% {
    transform: translateY(0);
  }
  12% {
    transform: translateY(3px);
  }
  24% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes bounce-right-animation {
  0% {
    transform: translateX(0);
  }
  4% {
    transform: translateX(3px);
  }
  8% {
    transform: translateX(0);
  }
  12% {
    transform: translateX(3px);
  }
  24% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}
.bounce {
  animation: bounce-animation 2.5s infinite;
}

.bounce-right {
  animation: bounce-right-animation 2.5s infinite;
}

strong {
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
}
.text.paragraph img {
  max-width: 100%;
  width: auto;
}
.text.paragraph .button {
  margin-top: 1rem;
}
.text.paragraph blockquote {
  font-size: 2rem;
  margin: 0 5em;
}
.text.paragraph blockquote p {
  margin-top: 1.5em;
  line-height: 3;
  color: #41a8ce;
}
@media (max-width: 800px) {
  .text.paragraph blockquote {
    font-size: 1.1rem;
    margin: 0 3em;
  }
  .text.paragraph blockquote p {
    line-height: 2;
  }
}
.button {
  text-transform: uppercase;
  padding: 0.6rem 2rem;
  border: 3px solid #003058;
  color: #FFFFFF;
  border-radius: 1.5rem;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  white-space: nowrap;
  display: inline-block;
}
.button.light {
  border: 3px solid #41a8ce;
  background-color: transparent;
}
.button.light.highlighted {
  border: none;
  background-color: #bfcfe0;
  color: #003058;
}
.button.dark {
  color: #013e7d;
}
.button.dark.ghost {
  border: 3px solid #FFFFFF;
  background-color: rgba(1, 62, 125, 0.7);
  color: #FFFFFF;
  opacity: 1;
  text-shadow: none;
}
.button.ghost {
  border: none;
  opacity: 0.7;
  background-color: #FFFFFF;
  color: #003058;
}
.hero-item {
  position: relative;
  background-position: center;
  background-size: cover;
  background-color: #252525;
}
.hero-item.large {
  height: 56.25vw;
}
.hero-item.small {
  height: 28vw;
}
.hero-item .overlay {
  height: 150px;
  width: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(0, 62, 126, 0) 0%, rgba(0, 62, 126, 0.2) 32%, rgba(0, 62, 126, 0.8) 100%);
}
.hero-item .underlay {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
.hero-item .hero-item-content {
  color: #FFFFFF;
  position: absolute;
  width: 100%;
  bottom: 7.5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  height: 50%;
  overflow: hidden;
  text-shadow: 1px 1px 6px #000000;
}
.hero-item .hero-item-content h1, .hero-item .hero-item-content h4, .hero-item .hero-item-content p, .hero-item .hero-item-content a {
  color: #FFFFFF;
}
.hero-item .hero-item-content p {
  max-width: 40%;
  font-size: 1rem;
}
.hero-item .hero-item-content a {
  display: inline-block;
  margin-top: 1rem;
}
@media (min-width: 992px) {
  .hero-item .hero-item-content h1 {
    font-size: 5em;
  }
}
@media (min-width: 1200px) {
  .hero-item .hero-item-content p {
    font-size: 1.2rem;
  }
}
.hero-item.alternate-styling .overlay {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 32%, rgba(0, 0, 0, 0.8) 100%);
}
@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("/fonts/fontawesome/webfonts/fa-brands-400.eot");
  src: url("/fonts/fontawesome/webfonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("/fonts/fontawesome/webfonts/fa-brands-400.woff2") format("woff2"), url("/fonts/fontawesome/webfonts/fa-brands-400.woff") format("woff"), url("/fonts/fontawesome/webfonts/fa-brands-400.ttf") format("truetype"), url("/fonts/fontawesome/webfonts/fa-brands-400.svg#fontawesome") format("svg");
}
@font-face {
  font-family: "Font Awesome 5 Regular";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("/fonts/fontawesome/webfonts/fa-regular-400.eot");
  src: url("/fonts/fontawesome/webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("/fonts/fontawesome/webfonts/fa-regular-400.woff2") format("woff2"), url("/fonts/fontawesome/webfonts/fa-regular-400.woff") format("woff"), url("/fonts/fontawesome/webfonts/fa-regular-400.ttf") format("truetype"), url("/fonts/fontawesome/webfonts/fa-regular-400.svg#fontawesome") format("svg");
}
@font-face {
  font-family: "Font Awesome 5 Solid";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("/fonts/fontawesome/webfonts/fa-solid-900.eot");
  src: url("/fonts/fontawesome/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("/fonts/fontawesome/webfonts/fa-solid-900.woff2") format("woff2"), url("/fonts/fontawesome/webfonts/fa-solid-900.woff") format("woff"), url("/fonts/fontawesome/webfonts/fa-solid-900.ttf") format("truetype"), url("/fonts/fontawesome/webfonts/fa-solid-900.svg#fontawesome") format("svg");
}
.hero {
  width: 100%;
}
.hero .slick-dots {
  left: 50%;
  transform: translateX(-50%);
  text-align: left;
  bottom: 2em;
  padding: auto;
}
.hero .slick-dots li, .hero .slick-dots li.slick-active {
  margin: 0;
}
.hero .slick-dots li button:before, .hero .slick-dots li.slick-active button:before {
  color: #FFFFFF;
  opacity: 1;
  content: "\f111";
  font-family: "Font Awesome 5 Regular";
  font-size: 12px;
}
.hero .slick-dots li.slick-active button:before {
  font-family: "Font Awesome 5 Solid";
}
.mini-menu {
  width: 90%;
  margin: auto;
}
.mini-menu hr {
  border-top: 1px solid #FFFFFF;
  width: 35%;
  margin-top: 0.5rem;
}
.mini-menu h3 {
  font-size: 3.5rem;
  line-height: 1;
  margin-bottom: 1rem;
}
.mini-menu .text {
  color: #252525;
  font-size: 1.2rem;
  padding-bottom: 0;
}
.mini-menu .links {
  background-color: #013e7d;
  text-align: center;
}
@media (min-width: 992px) {
  .mini-menu .links {
    transform: translateY(-8rem);
  }
}
.mini-menu .links h4 {
  color: #41a8ce;
  font-family: "Avenir LT W01_45 Book", arial, sans-serif;
  line-height: 1;
  margin-bottom: 0;
}
.mini-menu .links .link {
  margin-bottom: 1.2rem;
}
.mini-menu .links a {
  color: #FFFFFF;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  font-size: 2rem;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .mini-menu .links a {
    font-size: 2.38rem;
  }
}
.mini-menu .text-container,
.mini-menu .links {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
}
.mini-menu .text-container .content,
.mini-menu .links .content {
  padding: 3rem;
}
.mini-menu .active-indicator {
  background-color: #41a8ce;
  width: 35%;
  height: 8px;
  margin-bottom: 1rem;
}
.mini-menu .arrow {
  color: #FFFFFF;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.paragraph + .paragraph.mini-menu {
  margin-top: 8rem;
}
.paragraph.image h2, .paragraph.image h4 {
  text-align: center;
}
.paragraph.image .caption {
  font-style: italic;
}
video {
  width: 100%;
}
.remote-video {
  position: relative;
  padding-bottom: 56.25%;
}
.remote-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.paragraph.video h2, .paragraph.video h4 {
  text-align: center;
}
.image-hover {
  position: relative;
  overflow: hidden;
}
.image-hover .hover-content {
  opacity: 0;
  position: absolute;
  bottom: -100%;
  left: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
  padding: 2rem;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.image-hover .hover-content h2, .image-hover .hover-content h4, .image-hover .hover-content div {
  color: #FFFFFF;
}
.image-hover:hover .hover-content {
  opacity: 1;
  transition: bottom 0.5s, opacity 0.5s linear;
  bottom: 0;
}
.paragraph.image-collection h2, .paragraph.image-collection h4 {
  text-align: center;
}
.image-text-item {
  min-height: 400px;
  opacity: 0;
  margin-top: 3rem;
  transition: opacity 500ms ease, margin-top 500ms ease;
  position: relative;
}
.image-text-item .text.paragraph {
  padding: 3rem;
  background-color: #f0f0f0;
  height: 100%;
}
.image-text-item .text.paragraph.editor-body h1.body-large-headline {
  font-size: 3rem;
}
.image-text-item.visible {
  min-height: 0;
  opacity: 1;
  margin-top: 0;
}
.image-text-item .image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}
.image-text-item .waypoint {
  position: absolute;
  top: 200px;
}
.image-text-collection h2,
.image-text-collection h4 {
  text-align: center;
}
.image-text-collection .image-text-items {
  margin-top: 3rem;
}
.timeline-paragraph h2,
.timeline-paragraph h4 {
  text-align: center;
}
.timeline-paragraph .timeline-item-dateinner {
  background-color: #41a8ce;
}
.timeline-paragraph .entry h4 {
  text-align: left;
}
.timeline-paragraph .entry .text.paragraph {
  margin-top: 1rem;
}
.third-party-embed iframe {
  width: 100%;
  border: none;
}
.media-text {
  margin: auto;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  padding: 3rem;
}
.media-text hr {
  border-top: 1px solid #FFFFFF;
  width: 35%;
  margin-top: 0.5rem;
}
.media-text .media,
.media-text .text {
  padding-bottom: 20px;
}
@media (min-width: 992px) {
  .media-text .media:first-of-type,
  .media-text .text:first-of-type {
    padding-bottom: 0;
  }
}
.media-text .text {
  font-size: 1.2rem;
}
.media-text.theme-light {
  background-color: #f0f0f0;
}
.media-text.theme-dark {
  background-color: #003058;
}
.media-text.theme-dark .text p,
.media-text.theme-dark .text span,
.media-text.theme-dark .text div,
.media-text.theme-dark .text h1,
.media-text.theme-dark .text h2,
.media-text.theme-dark .text h3,
.media-text.theme-dark .text h4,
.media-text.theme-dark .text h5,
.media-text.theme-dark .text h6,
.media-text.theme-dark .text blockquote,
.media-text.theme-dark .text ul,
.media-text.theme-dark .text ol,
.media-text.theme-dark .text li {
  color: #FFFFFF;
}
.virtual-tour {
  padding-top: 4rem;
}
.virtual-tour .content {
  height: 300px;
  padding: 0.75rem;
  background-position: center;
  background-size: cover;
}
@media (min-width: 768px) {
  .virtual-tour .content {
    padding: 2rem;
  }
}
.virtual-tour .content .headline {
  font-size: 3rem;
  color: #FFFFFF;
  text-transform: uppercase;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
}
.market-card {
  padding: 3rem 3rem 0;
  border: none;
  text-align: center;
}
.market-card[role=button]:hover {
  cursor: pointer;
  background-color: #f8f8f8;
}
@media (min-width: 768px) {
  .market-card {
    padding-bottom: 3rem;
  }
}
.market-card .content {
  display: inline-block;
  text-align: left;
}
.market-card h3 {
  font-size: 2.5rem;
  padding-top: 1rem;
  text-align: center;
}
@media (min-width: 768px) {
  .market-card h3 {
    text-align: left;
  }
}
.market-card img {
  width: auto;
  height: auto;
  max-width: 200px;
  margin: auto;
  display: block;
}
@media (min-width: 768px) {
  .market-card img {
    max-width: 100%;
  }
}
.market-card .active-indicator {
  display: none;
  background-color: #41a8ce;
  width: 35%;
  height: 8px;
}
.market-card.active img {
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.3);
}
.market-card.active .active-indicator {
  display: block;
}
.market-map {
  text-align: center;
}
.market-map svg {
  max-width: 80%;
}
.market-map svg path,
.market-map svg polygon {
  fill: #cccccc;
  stroke: #FFFFFF;
  stroke-width: 3px;
}
.market-map svg path.active,
.market-map svg polygon.active {
  fill: #013e7d;
}
.market-map svg path.active:hover,
.market-map svg polygon.active:hover {
  fill: #003058;
}
.market-map svg text {
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  fill: #b3b3b3;
}
.market-map svg circle {
  fill: #b3b3b3;
}
.market-map svg circle:hover {
  fill: #41a8ce;
  cursor: pointer;
}
.market-map svg line {
  stroke: #b3b3b3;
  stroke-width: 1px;
}
.market-slider-item {
  position: relative;
  border-left: 1px solid #41a8ce;
}
.market-slider-item .content {
  position: absolute;
  bottom: 0;
  background-color: transparent;
  padding: 4rem 1.5rem;
  z-index: 3;
}
.market-slider-item .active-indicator {
  background-color: #41a8ce;
  width: 55%;
  height: 8px;
  margin-bottom: 3rem;
}
.market-slider-item h3 {
  color: #FFFFFF;
  font-size: 3rem;
  margin-bottom: 0;
}
.market-slider-item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 48, 88, 0.75);
  height: 100%;
  width: 100%;
}

.slick-current .market-slider-item .overlay {
  display: none;
}
.market-slider {
  position: relative;
  width: 90%;
  margin: auto;
}
.market-slider > .content {
  background-color: #003058;
}
.market-slider .slider {
  padding: 0;
}
.market-slider .arrows {
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
  margin-top: -50%;
  transform: translateY(50%);
  height: 100%;
}
.market-slider .arrows .arrow-prev,
.market-slider .arrows .arrow-next {
  font-size: 2.5rem;
  border: 3px solid #013e7d;
  height: 64px;
  width: 64px;
  border-radius: 64px;
  text-align: center;
  line-height: 1.65;
  color: #FFFFFF;
}
.market-slider .arrows .arrow-prev {
  margin-top: 1rem;
  padding-right: 0.3rem;
}
.market-slider .arrows .arrow-next {
  padding-left: 0.3rem;
  border-color: #41a8ce;
}
.article-teaser .date {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}
.article-teaser .date-value {
  margin-left: 1rem;
  color: #41a8ce;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
}
.article-teaser h3 {
  margin-bottom: 1rem;
}
.article-teaser .text p {
  color: #252525;
}
.recent-articles-list .headline hr {
  display: none;
}
.recent-articles-list .headline.vertical hr {
  display: none;
}
@media (min-width: 992px) {
  .recent-articles-list .headline.vertical h2 {
    transform: translate(-50%, -100%) rotate(-90deg);
    transform-origin: bottom right;
    font-size: 3rem;
    width: -webkit-max-content;
    width: max-content;
    background-color: #FFFFFF;
    z-index: 1;
    padding-left: 0.5rem;
  }
  .recent-articles-list .headline.vertical hr {
    display: block;
    transform: translateX(50%) rotate(-90deg);
    border-top: 2px solid #003058;
    width: 100%;
    position: absolute;
    transform-origin: left;
    bottom: 0;
    padding: 0;
    margin: 0;
    z-index: 0;
  }
}
.recent-articles-list .articles {
  border-top: 2px solid #003058;
  border-bottom: 2px solid #003058;
  padding-top: 2rem;
}
.recent-articles-list .more-link {
  text-align: right;
  margin-top: 1.5rem;
}
.recent-articles-list .more-link a {
  color: #003058;
  text-transform: uppercase;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  padding: 0.3rem 1rem;
  border-bottom: 2px solid #003058;
}
.building-grid-item {
  color: #003058;
  font-size: 1.6rem;
  margin-bottom: 2rem;
}
.building-grid-item a {
  color: #003058;
}
.selector-item:hover {
  cursor: pointer;
}
.selector-item:last-of-type .building-mini {
  margin-bottom: 0;
}
.selector {
  padding: 0.7rem;
}
.selector .selector-header {
  display: flex;
  align-items: center;
  font-size: 2rem;
  color: #41a8ce;
}
.selector .selector-header:hover {
  cursor: pointer;
}
@media (min-width: 992px) {
  .selector .selector-header {
    justify-content: flex-end;
  }
}
.selector .selector-header h3 {
  margin-left: 1rem;
}
.selector .selector-header svg:hover {
  cursor: pointer;
}
.selector .item-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  padding: 0.7rem;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 400px;
}
@media (min-width: 992px) {
  .selector.horizontal .item-list {
    left: 100%;
    max-height: 550px;
    top: -3rem;
    width: auto;
  }
  .selector.horizontal .item-list .selector-header {
    display: none;
  }
}
.selector.light .selector-header {
  color: #FFFFFF;
}
.selector.light .selector-header h3 {
  color: #FFFFFF;
}
.selector.light .item-list .selector-header {
  color: #41a8ce;
}
.selector.light .item-list .selector-header h3 {
  color: #003058;
}
.building-mini {
  margin-bottom: 1rem;
}
.building-mini img {
  width: 100%;
}
.building-mini .title {
  font-size: 1.2em;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  color: #41a8ce;
  white-space: nowrap;
}
@media (min-width: 992px) {
  .building-switcher {
    text-align: right;
  }
}
.building-switcher .building-address {
  padding: 0 0.7rem;
  font-size: 1.2rem;
}
.building-switcher .current-building,
.building-switcher .building-selector-wrapper {
  position: relative;
}
.building-switcher .building-status {
  color: #41a8ce;
  text-transform: uppercase;
  font-size: 0.8rem;
  margin-top: 1rem;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
}
.building-switcher .building-count {
  color: #41a8ce;
  padding: 0 0.7rem;
  display: inline-block;
}
.building-switcher .building-count:hover {
  cursor: pointer;
}
.building-switcher .item-list {
  text-align: left;
}
.position-card {
  text-align: center;
}
.position-card.featured {
  text-align: left;
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .position-card.featured {
    text-align: right;
  }
}
.position-card img {
  width: auto;
  height: auto;
  margin-bottom: 1rem;
}
.position-card .job-title {
  text-transform: uppercase;
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}
.position-card .person-name {
  font-size: 1.2rem;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
}
.building-masthead {
  padding-top: 2rem;
}
@media (min-width: 992px) {
  .building-masthead {
    text-align: right;
  }
}
.building-masthead .market {
  text-transform: uppercase;
  font-size: 1.2em;
  padding: 0 0.7rem;
}
.building-masthead .position-card {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 2px solid #003058;
}
.building-masthead .main-image img {
  width: 100%;
}
.building-masthead .property-title {
  padding: 0 0.7rem;
}
.map-popup {
  background-color: #FFFFFF;
  padding: 1rem;
  width: 400px;
  transform: translate(-50%, -100%);
  position: absolute;
  z-index: 1000;
}
.map-popup .popup-close-trigger {
  width: 100%;
  text-align: right;
  font-size: 1rem;
}
.map-popup .popup-close-trigger:hover {
  cursor: pointer;
}
.location-pin:hover {
  cursor: pointer;
}
.location-pin .fa-map-pin {
  font-size: 2.5em;
  color: #41a8ce;
}
.location-pin .fa-utensils,
.location-pin .fa-parking,
.location-pin .fa-subway,
.location-pin .fa-plane,
.location-pin .fa-dollar-sign,
.location-pin .fa-dumbbell,
.location-pin .fa-shopping-cart,
.location-pin .fa-hotel,
.location-pin .fa-home,
.location-pin .fa-running,
.location-pin .fa-tag {
  font-size: 1.5em;
  color: #41a8ce;
}
.location-pin .pin-text {
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  font-size: 2em;
  min-width: 200px;
  padding-right: 0.5rem;
  text-align: right;
  position: absolute;
  left: -200px;
  top: 0;
}
.location-pin.secondary .pin-text {
  font-size: 1.5em;
}
.google-map {
  height: 200px;
}
@media (min-width: 768px) {
  .google-map {
    height: 500px;
  }
}
.building-fact {
  margin-bottom: 1.5rem;
}
.building-fact .value {
  font-size: 4rem;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  line-height: 1;
}
.building-fact.grid {
  text-align: center;
}
.building-fact.grid .label {
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  font-size: 1.3rem;
}
.building-fact.grid .value {
  font-family: "Avenir LT W01_45 Book", arial, sans-serif;
  font-size: 1rem;
}
.body {
  width: 100%;
}
.body.placeholder {
  margin-bottom: 1.5em;
}
.body .body-line {
  width: 100%;
  height: 18px;
  margin-bottom: 0.2em;
  background-color: #252525;
}
.body .body-line:last-child {
  width: 60%;
}
.building-facts .facts,
.building-facts .description-amenities,
.building-facts .buttons {
  padding: 0 2rem;
}
@media (min-width: 768px) {
  .building-facts .additional-facts,
  .building-facts .buttons,
  .building-facts .facts {
    text-align: right;
  }
  .building-facts .facts {
    justify-content: flex-end;
  }
}
.building-facts .facts {
  display: flex;
}
.building-facts .toggle {
  padding-right: 1rem;
  color: #41a8ce;
  font-size: 2rem;
}
@media (min-width: 768px) {
  .building-facts .description,
  .building-facts .amenities {
    max-width: 60%;
  }
}
.building-facts .description-amenities .label {
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
}
.building-facts .btn {
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.building-facts .btn:first-of-type {
  margin-right: 0;
}
@media (min-width: 768px) {
  .building-facts .btn {
    margin-left: 1rem;
    margin-right: 0;
  }
  .building-facts .btn:first-of-type {
    margin-left: 0;
  }
}
.gallery-item {
  border-left: 1px solid #41a8ce;
  height: 300px;
}
@media (min-width: 768px) {
  .gallery-item {
    height: 700px;
  }
}
.gallery-item img {
  height: 100%;
  width: auto;
}
.gallery-switcher-item {
  display: block;
  font-size: 1.2em;
  font-family: "Avenir LT W01_45 Book", arial, sans-serif;
  text-transform: uppercase;
  color: #41a8ce;
}
@media (min-width: 768px) {
  .gallery-switcher-item {
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .gallery-switcher-item + .gallery-switcher-item {
    margin-left: 2em;
    padding-left: 2em;
    border-left: 2px solid #013e7d;
  }
}
.gallery-switcher-item.active {
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  color: #013e7d;
}
.gallery-switcher-item:hover {
  cursor: pointer;
}
.file {
  font-size: 1.2rem;
}
.file a {
  color: #013e7d;
}
.file .icon {
  color: #41a8ce;
}
.more-list {
  padding: 0.7rem;
}
.more-list .more-list-header {
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .more-list .more-list-header {
    justify-content: flex-end;
  }
}
.more-list .more-list-header .more-list-toggle {
  color: #41a8ce;
  font-size: 2rem;
  margin-right: 1rem;
  cursor: pointer;
}
.more-list .item-list {
  position: absolute;
  top: 0;
  left: 0;
  max-height: 300px;
  overflow-y: auto;
  background-color: #FFFFFF;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  padding: 0.7rem;
  z-index: 2;
  text-align: right;
  margin-right: 0.95rem;
}
@media (min-width: 768px) {
  .more-list .item-list {
    right: 0;
    left: auto;
  }
}
@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("/fonts/fontawesome/webfonts/fa-brands-400.eot");
  src: url("/fonts/fontawesome/webfonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("/fonts/fontawesome/webfonts/fa-brands-400.woff2") format("woff2"), url("/fonts/fontawesome/webfonts/fa-brands-400.woff") format("woff"), url("/fonts/fontawesome/webfonts/fa-brands-400.ttf") format("truetype"), url("/fonts/fontawesome/webfonts/fa-brands-400.svg#fontawesome") format("svg");
}
@font-face {
  font-family: "Font Awesome 5 Regular";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("/fonts/fontawesome/webfonts/fa-regular-400.eot");
  src: url("/fonts/fontawesome/webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("/fonts/fontawesome/webfonts/fa-regular-400.woff2") format("woff2"), url("/fonts/fontawesome/webfonts/fa-regular-400.woff") format("woff"), url("/fonts/fontawesome/webfonts/fa-regular-400.ttf") format("truetype"), url("/fonts/fontawesome/webfonts/fa-regular-400.svg#fontawesome") format("svg");
}
@font-face {
  font-family: "Font Awesome 5 Solid";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("/fonts/fontawesome/webfonts/fa-solid-900.eot");
  src: url("/fonts/fontawesome/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("/fonts/fontawesome/webfonts/fa-solid-900.woff2") format("woff2"), url("/fonts/fontawesome/webfonts/fa-solid-900.woff") format("woff"), url("/fonts/fontawesome/webfonts/fa-solid-900.ttf") format("truetype"), url("/fonts/fontawesome/webfonts/fa-solid-900.svg#fontawesome") format("svg");
}
.gallery .gallery-switcher {
  margin-bottom: 3rem;
}
.gallery .gallery-carousel {
  align-items: flex-end;
}
.gallery .arrow-prev,
.gallery .arrow-next {
  float: right;
  clear: both;
  font-size: 2.5rem;
  border: 3px solid #013e7d;
  height: 64px;
  width: 64px;
  border-radius: 64px;
  text-align: center;
  line-height: 1.65;
}
.gallery .arrow-prev {
  margin-top: 1rem;
  padding-right: 0.3rem;
}
.gallery .arrow-next {
  padding-left: 0.3rem;
  color: #41a8ce;
  border-color: #41a8ce;
}
@media (min-width: 768px) {
  .gallery .slick-list {
    max-width: 80vw;
    margin-right: 0;
    margin-left: auto;
  }
}
.gallery .slick-dots {
  text-align: right;
  padding: 0 10vw;
  font-family: "Font Awesome 5 Solid";
}
.gallery .slick-dots li {
  margin: 0;
}
.gallery .slick-dots li button:before {
  color: #003058;
  opacity: 1;
  content: "\f111";
  font-family: "Font Awesome 5 Regular";
  font-size: 12px;
}
.gallery .slick-dots li.slick-active {
  margin: 0;
}
.gallery .slick-dots li.slick-active button:before {
  font-family: "Font Awesome 5 Solid";
  color: #41a8ce;
}
.building-fact-grid .btn {
  margin-left: 1rem;
  margin-bottom: 1rem;
}
.building-fact-grid .btn:first-of-type {
  margin-left: 0;
}
.building-fact-grid .buttons {
  text-align: center;
  margin-top: 2rem;
}
.position-grid h3 {
  text-align: center;
}
.property-card {
  text-align: right;
}
.property-card .address div,
.property-card .property-description {
  color: #FFFFFF;
}
.property-card .address {
  font-size: 1.6rem;
  margin-bottom: 1.3rem;
}
.property-card .property-description {
  margin-bottom: 1.3rem;
  padding-left: 40%;
}
.property-card .image {
  margin-top: -10rem;
  padding: 0 3rem 0 2rem;
}
.property-card .property-detail-link {
  margin-top: 3rem;
}
.property-list-item {
  color: #003058;
  text-align: left;
  margin: 0.5rem 0;
}
.property-list-item .address div {
  color: #003058;
  font-size: 1rem;
}
.property-list-item .image {
  margin: 0;
  text-align: right;
  padding: 0;
}
.property-list-item .image img {
  max-height: 100px;
  width: auto;
  max-width: 100%;
}

a .property-list-item {
  cursor: pointer;
}
.property-grid-item {
  color: #FFFFFF;
  font-size: 1.6rem;
  margin-bottom: 2rem;
}
.property-grid-item a {
  color: #FFFFFF;
}
.property-grid-item.light a {
  color: #003058;
}
.property-widget-picker-item {
  display: inline-block;
  margin: 0 1.5rem 1rem 0;
}
.property-widget-picker-item:last-of-type {
  margin-right: 0;
}
.property-widget-picker-item:hover {
  cursor: pointer;
}
.property-widget-picker {
  margin-bottom: 1rem;
}
.property-picker {
  background-color: #013e7d;
  padding: 2rem;
  margin-top: 0;
}
@media (min-width: 992px) {
  .property-picker {
    margin-top: 5rem;
    padding: 2rem 4rem 4rem;
  }
}
.article-detail-page .date {
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  color: #41a8ce;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.team-selector.align-center {
  text-align: center;
}
.team-selector .menu-branch {
  display: inline-block;
  padding: 0 1.5rem;
  font-size: 1.1rem;
  text-transform: uppercase;
  border-right: 1px solid #252525;
}
.team-selector .menu-branch a {
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
}
.team-selector .menu-branch:last-of-type {
  border-right: none;
}
.team-selector .menu-branch.active {
  color: #003058;
}
.team-selector .submenu {
  margin-top: 1rem;
}
.team-selector .submenu .menu-branch {
  font-size: 1rem;
}
.person-detail-page {
  position: relative;
  min-height: 900px;
}
.person-detail-page p {
  font-size: 1.1rem;
}
.person-detail-page div, .person-detail-page p {
  color: #252525;
}
.person-detail-page h1.title {
  font-size: 5.5rem;
  margin-bottom: 2rem;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  z-index: 2;
  position: relative;
}
@media (min-width: 768px) {
  .person-detail-page {
    padding-top: 4rem;
  }
  .person-detail-page .image {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    z-index: 1;
  }
  .person-detail-page .image img {
    z-index: 1;
  }
}
.person-detail-page .body-1,
.person-detail-page .body {
  padding-top: 2rem;
}
.person-detail-page .body-1 .page-section.padded,
.person-detail-page .body .page-section.padded {
  padding-bottom: 0;
}
.person-detail-page .body-2 {
  background-color: #003058;
  padding: 4rem 4rem 0;
}
.person-detail-page .body-2 div, .person-detail-page .body-2 span, .person-detail-page .body-2 h4, .person-detail-page .body-2 p {
  color: #FFFFFF;
}
@media (min-width: 768px) {
  .person-detail-page .body {
    margin-top: 3rem;
    column-count: 3;
    grid-column-gap: 4rem;
    column-gap: 4rem;
    padding-top: 0;
  }
  .person-detail-page .body-1 .page-section.padded,
  .person-detail-page .body .page-section.padded {
    padding-bottom: 4rem;
  }
}
.person-detail-page .job-title {
  font-size: 2rem;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .person-detail-page.has-image .body-1 {
    width: 50%;
    padding-right: 12rem;
    padding-top: 0;
  }
  .person-detail-page.has-image .body-2 {
    padding: 4rem 12rem 0 4rem;
    width: 50%;
    transform: translateX(4rem);
  }
  .person-detail-page.has-image .job-title,
  .person-detail-page.has-image .title {
    width: 50%;
    padding-right: 3rem;
  }
}
.position-teaser {
  text-align: center;
  margin-bottom: 3rem;
}
.position-teaser .person-image {
  margin-bottom: 1rem;
}
.position-teaser .job-title {
  text-transform: uppercase;
  font-size: 0.9rem;
}
.position-teaser .person-name {
  font-size: 2rem;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
}
.team-grid .position-teaser {
  padding: 0 2rem;
}
.search-result {
  margin-bottom: 3rem;
}
.search-result .icon {
  font-size: 2rem;
  color: #41a8ce;
  text-align: right;
}
.search-result .teaser {
  color: #252525;
  font-size: 1.1rem;
}
.spinner {
  width: 100%;
  text-align: center;
}
.spinner img {
  height: 48px;
  width: 48px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.search-page h4 {
  margin-bottom: 2rem;
}
.modal-overlay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 4;
}
.modal-overlay .modal-close-trigger {
  right: 0;
  top: 0;
  line-height: 1;
  position: absolute;
  padding: 1rem;
  font-size: 3rem;
}
.search-box {
  display: flex;
  align-items: flex-end;
}
.search-box form {
  display: flex;
}
.search-box form input {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #003058;
  font-size: 0.9rem;
}
.search-box form input.form-control {
  padding: 0.1rem;
  height: auto;
  background-color: transparent;
}
.search-box form input::placeholder {
  font-style: italic;
}
.search-box form .btn-primary {
  background-color: transparent;
  border: none;
  color: #003058;
  padding: 0;
  line-height: auto;
  font-size: 1.25rem;
}
.search-box .menu {
  margin-right: 1em;
}

.has-hero .search-box form input,
.search-box.theme-light form input {
  border-bottom: 2px solid #FFFFFF;
}
.has-hero .search-box form input.form-control,
.search-box.theme-light form input.form-control {
  color: #FFFFFF;
}
.has-hero .search-box form input::placeholder,
.search-box.theme-light form input::placeholder {
  color: #FFFFFF;
}
.has-hero .search-box form .btn-primary,
.search-box.theme-light form .btn-primary {
  color: #FFFFFF;
}
.modal-menu {
  text-align: right;
}
.modal-menu .modal-menu-trigger {
  font-size: 2rem;
  color: #003058;
  padding: 0 1rem;
}
.modal-menu .search-box-container {
  margin-top: 3rem;
}
.modal-menu .modal-close-trigger {
  color: #FFFFFF;
  z-index: 2;
}
.modal-menu .expand-trigger {
  color: #FFFFFF;
  position: absolute;
  right: 0;
  padding-right: 2rem;
  font-size: 1.5rem;
}
@media (min-width: 992px) {
  .modal-menu .modal-close-trigger {
    color: #003058;
  }
}
.modal-menu .modal-menu-container {
  background-color: #003058;
  padding: 1rem 4rem 3rem;
  text-align: left;
  overflow: auto;
  height: 100vh;
}
.modal-menu .modal-menu-container img {
  height: 48px;
  margin-bottom: 2rem;
}
.modal-menu .modal-menu-container .menu .link {
  margin-bottom: 0.5rem;
}
.modal-menu .modal-menu-container .menu a {
  color: #FFFFFF;
  font-size: 1.7rem;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
}
.modal-menu .modal-menu-container .menu .menu-branch .menu-branch {
  margin-left: 3rem;
}
.modal-menu .modal-menu-container .menu .menu-branch .link .active-indicator {
  background-color: #41a8ce;
  height: 8px;
  width: 36px;
  position: absolute;
  display: none;
  left: 15px;
  transform: translateY(0.85rem);
}
.modal-menu .modal-menu-container .menu .menu-branch .link.active .active-indicator {
  display: block;
}
.modal-menu .modal-menu-container .menu .menu-branch .link.active a {
  color: #41a8ce;
}

.has-hero .modal-menu .modal-menu-trigger {
  color: #FFFFFF;
}
header.page-header {
  padding: 1.5rem 0;
  margin-bottom: 4rem;
}
header.page-header.has-hero {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  color: #FFFFFF;
  margin-bottom: 0;
}
header.page-header.has-hero .menu a {
  color: #FFFFFF;
}
header.page-header .branding {
  display: flex;
  align-items: flex-end;
}
header.page-header .branding img {
  height: 48px;
  margin-right: 3rem;
}
header.page-header .menu a {
  color: #003058;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
}
header.page-header .main-menu .menu {
  display: flex;
}
header.page-header .main-menu .menu a {
  font-size: 1.2rem;
  padding: 0 0.8em;
}
header.page-header .search-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
header.page-header .page-header-content {
  align-items: flex-end;
}
.page-footer h2 {
  color: #FFFFFF;
}
.page-footer .pre-footer {
  width: 100%;
  position: relative;
  z-index: 2;
  color: #ffffff;
}
.page-footer .pre-footer .flex-content {
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  text-align: center;
  padding: 2rem 0;
  width: 100%;
}
.page-footer .pre-footer .flex-content div {
  color: #41a8ce;
}
@media (min-width: 992px) {
  .page-footer .flex-container {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }
  .page-footer .flexible {
    display: flex;
    width: 50%;
  }
  .page-footer .limit-half-width {
    max-width: 700px;
  }
}
.page-footer .offset-up {
  margin-top: -60px;
}
.page-footer .offset-down {
  margin-top: 60px;
}
@media (min-width: 768px) {
  .page-footer .menu {
    display: flex;
    justify-content: center;
  }
}
.page-footer .menu a {
  color: #FFFFFF;
  padding: 0 1em;
  display: block;
  text-align: center;
}
@media (min-width: 768px) {
  .page-footer .menu a {
    border-left: 1px solid #FFFFFF;
  }
}
.page-footer .menu .menu-branch:first-child a {
  border-left: none;
}
@media (min-width: 768px) {
  .page-footer .menu .menu-branch:last-child a {
    padding-right: 0;
  }
}
.page-footer .footer-content {
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.page-footer .footer .col-12 {
  justify-content: center;
  margin-bottom: 1.5rem;
}
@media (min-width: 992px) {
  .page-footer .footer .col-12 {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .page-footer .footer-menu .menu {
    justify-content: flex-end;
  }
}
.page-footer .copyright {
  align-items: center;
}
@media (min-width: 992px) {
  .page-footer .copyright-menu .menu {
    justify-content: flex-start;
  }
}
.page-footer .icon-logo {
  align-items: center;
  text-align: center;
}
@media (min-width: 992px) {
  .page-footer .icon-logo {
    text-align: initial;
  }
}
.page-footer .icon-logo .logo-copyright {
  color: #FFFFFF;
}
.page-footer .icon-logo img {
  height: 48px;
  width: 48px;
  margin-right: 1em;
}
.page-footer .icon-logo .menu {
  margin-left: 1em;
}
.page-footer .icon-logo .menu .menu-branch:first-child a {
  border-left: 1px solid #FFFFFF;
}
.page-footer .fine-print {
  padding-bottom: 1rem;
  font-size: 0.7rem;
  font-style: italic;
}
