.hero-item {
  position: relative;
  background-position: center;
  background-size: cover;
  background-color: #252525;
}
.hero-item.large {
  height: 56.25vw;
}
.hero-item.small {
  height: 28vw;
}
.hero-item .overlay {
  height: 150px;
  width: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(0, 62, 126, 0) 0%, rgba(0, 62, 126, 0.2) 32%, rgba(0, 62, 126, 0.8) 100%);
}
.hero-item .underlay {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
.hero-item .hero-item-content {
  color: #FFFFFF;
  position: absolute;
  width: 100%;
  bottom: 7.5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  height: 50%;
  overflow: hidden;
  text-shadow: 1px 1px 6px #000000;
}
.hero-item .hero-item-content h1, .hero-item .hero-item-content h4, .hero-item .hero-item-content p, .hero-item .hero-item-content a {
  color: #FFFFFF;
}
.hero-item .hero-item-content p {
  max-width: 40%;
  font-size: 1rem;
}
.hero-item .hero-item-content a {
  display: inline-block;
  margin-top: 1rem;
}
@media (min-width: 992px) {
  .hero-item .hero-item-content h1 {
    font-size: 5em;
  }
}
@media (min-width: 1200px) {
  .hero-item .hero-item-content p {
    font-size: 1.2rem;
  }
}
.hero-item.alternate-styling .overlay {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 32%, rgba(0, 0, 0, 0.8) 100%);
}