.property-list-item {
  color: #003058;
  text-align: left;
  margin: 0.5rem 0;
}
.property-list-item .address div {
  color: #003058;
  font-size: 1rem;
}
.property-list-item .image {
  margin: 0;
  text-align: right;
  padding: 0;
}
.property-list-item .image img {
  max-height: 100px;
  width: auto;
  max-width: 100%;
}

a .property-list-item {
  cursor: pointer;
}