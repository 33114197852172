.building-masthead {
  padding-top: 2rem;
}
@media (min-width: 992px) {
  .building-masthead {
    text-align: right;
  }
}
.building-masthead .market {
  text-transform: uppercase;
  font-size: 1.2em;
  padding: 0 0.7rem;
}
.building-masthead .position-card {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 2px solid #003058;
}
.building-masthead .main-image img {
  width: 100%;
}
.building-masthead .property-title {
  padding: 0 0.7rem;
}