@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
.modal-menu {
  text-align: right;
}
.modal-menu .modal-menu-trigger {
  font-size: 2rem;
  color: #003058;
  padding: 0 1rem;
}
.modal-menu .search-box-container {
  margin-top: 3rem;
}
.modal-menu .modal-close-trigger {
  color: #FFFFFF;
  z-index: 2;
}
.modal-menu .expand-trigger {
  color: #FFFFFF;
  position: absolute;
  right: 0;
  padding-right: 2rem;
  font-size: 1.5rem;
}
@media (min-width: 992px) {
  .modal-menu .modal-close-trigger {
    color: #003058;
  }
}
.modal-menu .modal-menu-container {
  background-color: #003058;
  padding: 1rem 4rem 3rem;
  text-align: left;
  overflow: auto;
  height: 100vh;
}
.modal-menu .modal-menu-container img {
  height: 48px;
  margin-bottom: 2rem;
}
.modal-menu .modal-menu-container .menu .link {
  margin-bottom: 0.5rem;
}
.modal-menu .modal-menu-container .menu a {
  color: #FFFFFF;
  font-size: 1.7rem;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
}
.modal-menu .modal-menu-container .menu .menu-branch .menu-branch {
  margin-left: 3rem;
}
.modal-menu .modal-menu-container .menu .menu-branch .link .active-indicator {
  background-color: #41a8ce;
  height: 8px;
  width: 36px;
  position: absolute;
  display: none;
  left: 15px;
  transform: translateY(0.85rem);
}
.modal-menu .modal-menu-container .menu .menu-branch .link.active .active-indicator {
  display: block;
}
.modal-menu .modal-menu-container .menu .menu-branch .link.active a {
  color: #41a8ce;
}

.has-hero .modal-menu .modal-menu-trigger {
  color: #FFFFFF;
}