@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
.market-map {
  text-align: center;
}
.market-map svg {
  max-width: 80%;
}
.market-map svg path,
.market-map svg polygon {
  fill: #cccccc;
  stroke: #FFFFFF;
  stroke-width: 3px;
}
.market-map svg path.active,
.market-map svg polygon.active {
  fill: #013e7d;
}
.market-map svg path.active:hover,
.market-map svg polygon.active:hover {
  fill: #003058;
}
.market-map svg text {
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  fill: #b3b3b3;
}
.market-map svg circle {
  fill: #b3b3b3;
}
.market-map svg circle:hover {
  fill: #41a8ce;
  cursor: pointer;
}
.market-map svg line {
  stroke: #b3b3b3;
  stroke-width: 1px;
}