.modal-overlay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 4;
}
.modal-overlay .modal-close-trigger {
  right: 0;
  top: 0;
  line-height: 1;
  position: absolute;
  padding: 1rem;
  font-size: 3rem;
}