@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
.building-fact {
  margin-bottom: 1.5rem;
}
.building-fact .value {
  font-size: 4rem;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  line-height: 1;
}
.building-fact.grid {
  text-align: center;
}
.building-fact.grid .label {
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  font-size: 1.3rem;
}
.building-fact.grid .value {
  font-family: "Avenir LT W01_45 Book", arial, sans-serif;
  font-size: 1rem;
}