.selector {
  padding: 0.7rem;
}
.selector .selector-header {
  display: flex;
  align-items: center;
  font-size: 2rem;
  color: #41a8ce;
}
.selector .selector-header:hover {
  cursor: pointer;
}
@media (min-width: 992px) {
  .selector .selector-header {
    justify-content: flex-end;
  }
}
.selector .selector-header h3 {
  margin-left: 1rem;
}
.selector .selector-header svg:hover {
  cursor: pointer;
}
.selector .item-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  padding: 0.7rem;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 400px;
}
@media (min-width: 992px) {
  .selector.horizontal .item-list {
    left: 100%;
    max-height: 550px;
    top: -3rem;
    width: auto;
  }
  .selector.horizontal .item-list .selector-header {
    display: none;
  }
}
.selector.light .selector-header {
  color: #FFFFFF;
}
.selector.light .selector-header h3 {
  color: #FFFFFF;
}
.selector.light .item-list .selector-header {
  color: #41a8ce;
}
.selector.light .item-list .selector-header h3 {
  color: #003058;
}