@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
.building-facts .facts,
.building-facts .description-amenities,
.building-facts .buttons {
  padding: 0 2rem;
}
@media (min-width: 768px) {
  .building-facts .additional-facts,
  .building-facts .buttons,
  .building-facts .facts {
    text-align: right;
  }
  .building-facts .facts {
    justify-content: flex-end;
  }
}
.building-facts .facts {
  display: flex;
}
.building-facts .toggle {
  padding-right: 1rem;
  color: #41a8ce;
  font-size: 2rem;
}
@media (min-width: 768px) {
  .building-facts .description,
  .building-facts .amenities {
    max-width: 60%;
  }
}
.building-facts .description-amenities .label {
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
}
.building-facts .btn {
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.building-facts .btn:first-of-type {
  margin-right: 0;
}
@media (min-width: 768px) {
  .building-facts .btn {
    margin-left: 1rem;
    margin-right: 0;
  }
  .building-facts .btn:first-of-type {
    margin-left: 0;
  }
}