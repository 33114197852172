@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
.gallery-switcher-item {
  display: block;
  font-size: 1.2em;
  font-family: "Avenir LT W01_45 Book", arial, sans-serif;
  text-transform: uppercase;
  color: #41a8ce;
}
@media (min-width: 768px) {
  .gallery-switcher-item {
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .gallery-switcher-item + .gallery-switcher-item {
    margin-left: 2em;
    padding-left: 2em;
    border-left: 2px solid #013e7d;
  }
}
.gallery-switcher-item.active {
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  color: #013e7d;
}
.gallery-switcher-item:hover {
  cursor: pointer;
}