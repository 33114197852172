@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
.article-teaser .date {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}
.article-teaser .date-value {
  margin-left: 1rem;
  color: #41a8ce;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
}
.article-teaser h3 {
  margin-bottom: 1rem;
}
.article-teaser .text p {
  color: #252525;
}