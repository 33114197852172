@import url("https://fast.fonts.net/cssapi/59f56ae9-de30-42e9-aadd-a42a71255780.css");
.mini-menu {
  width: 90%;
  margin: auto;
}
.mini-menu hr {
  border-top: 1px solid #FFFFFF;
  width: 35%;
  margin-top: 0.5rem;
}
.mini-menu h3 {
  font-size: 3.5rem;
  line-height: 1;
  margin-bottom: 1rem;
}
.mini-menu .text {
  color: #252525;
  font-size: 1.2rem;
  padding-bottom: 0;
}
.mini-menu .links {
  background-color: #013e7d;
  text-align: center;
}
@media (min-width: 992px) {
  .mini-menu .links {
    transform: translateY(-8rem);
  }
}
.mini-menu .links h4 {
  color: #41a8ce;
  font-family: "Avenir LT W01_45 Book", arial, sans-serif;
  line-height: 1;
  margin-bottom: 0;
}
.mini-menu .links .link {
  margin-bottom: 1.2rem;
}
.mini-menu .links a {
  color: #FFFFFF;
  font-family: "Avenir LT W01_85 Heavy", arial, sans-serif;
  font-size: 2rem;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .mini-menu .links a {
    font-size: 2.38rem;
  }
}
.mini-menu .text-container,
.mini-menu .links {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
}
.mini-menu .text-container .content,
.mini-menu .links .content {
  padding: 3rem;
}
.mini-menu .active-indicator {
  background-color: #41a8ce;
  width: 35%;
  height: 8px;
  margin-bottom: 1rem;
}
.mini-menu .arrow {
  color: #FFFFFF;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.paragraph + .paragraph.mini-menu {
  margin-top: 8rem;
}