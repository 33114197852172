.more-list {
  padding: 0.7rem;
}
.more-list .more-list-header {
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .more-list .more-list-header {
    justify-content: flex-end;
  }
}
.more-list .more-list-header .more-list-toggle {
  color: #41a8ce;
  font-size: 2rem;
  margin-right: 1rem;
  cursor: pointer;
}
.more-list .item-list {
  position: absolute;
  top: 0;
  left: 0;
  max-height: 300px;
  overflow-y: auto;
  background-color: #FFFFFF;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  padding: 0.7rem;
  z-index: 2;
  text-align: right;
  margin-right: 0.95rem;
}
@media (min-width: 768px) {
  .more-list .item-list {
    right: 0;
    left: auto;
  }
}